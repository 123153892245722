<template>
  <div class="Notifications">
    <div class="table-wrapper">
      <notifications-filters :total-records="total"
                             @update="onFiltersUpdate" />

      <div class="loader" v-if="loading">
        <base-loader-dual-ring />
      </div>

      <base-alert type="info"
                  class="no-notifications"
                  v-if="!loading && notifications.length === 0">
        No notifications
      </base-alert>

      <table class="table" v-if="!loading && notifications.length > 0">
        <colgroup>
          <col width="20%">
          <col width="60%">
          <col width="20%">
        </colgroup>
        <thead>
          <tr>
            <th>Type</th>
            <th>Description</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="notification in notifications"
              :key="notification.id"
              :class="{ unread: !notification.read_at }">
            <td>
              {{ notification.type | notificationType }}
            </td>
            <td>
              <notification-description :notification="notification" />
            </td>
            <td>
              {{ notification.created_at | shortDate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import profileApi from '@/api/profileApi.js'
import NotificationsFilters from './NotificationsFilters.vue'
import NotificationDescription from '@/components/Common/NotificationDescription/NotificationDescription.vue'

export default {
  components: {
    NotificationsFilters,
    NotificationDescription
  },

  data () {
    return {
      notifications: [],
      total: 0,

      noNotifications: false,
      loading: false
    }
  },

  methods: {
    onFiltersUpdate (filters) {
      this.loadNotifications(filters)
    },

    async loadNotifications (params) {
      this.loading = true

      const response = await profileApi.getNotifications(params)

      this.notifications = response.items
      this.total = response.meta.total

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .Notifications {
    .table-wrapper {
      position: relative;
      min-height: 500px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .125);
      border-radius: 4px;
    }

    .loader {
      @include flex-center;

      width: 100%;
      height: 100%;

      margin-top: 60px;
    }

    .unread {
      background-color: $unread-notification-background-color;
    }

    .table th:first-child, .table td:first-child {
      padding-left: 14px;
    }

    .no-notifications {
      margin: 12px;
    }
  }
</style>

export default {
  props: {
    notification: {
      required: true,
      type: Object
    }
  },

  computed: {
    monitor () {
      return this.notification.data.monitor
    },

    date () {
      return this.notification.created_at
    },

    classes () {
      return {
        unread: !this.notification.read_at
      }
    },

    monitorRoute () {
      return {
        name: 'monitors.single',
        params: {
          id: this.monitor.id
        }
      }
    }
  }
}

<template>
  <div class="NotificationsHeader">
    <div class="left">
      <a href="#" @click.prevent="markAllAsRead">Mark all as Read</a>
    </div>
    <div class="right">
      <notifications-pagination
          :total="totalRecords"
          :take="filters.take"
          :skip="filters.skip"

          @prev="onPrev"
          @next="onNext" />
    </div>
  </div>
</template>

<script>
import NotificationsPagination from './NotificationsPagination.vue'
import profileApi from '@/api/profileApi.js'

export default {
  components: {
    NotificationsPagination
  },

  props: {
    totalRecords: {
      required: false,
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      filters: {
        skip: 0,
        take: 100
      }
    }
  },

  methods: {
    async markAllAsRead () {
      await profileApi.markNotificationsAsRead()

      this.refreshCurrentUser()
      this.$emit('update')
    },

    onNext () {
      this.filters.skip += this.filters.take
    },

    onPrev () {
      this.filters.skip -= this.filters.take
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  watch: {
    filters: {
      handler: function () {
        this.$emit('update', this.filters)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .NotificationsHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px;

    .left {
      display: flex;
      align-items: center;
    }

    .left {
      flex: 1 0;
    }

    .right {

    }
  }
</style>

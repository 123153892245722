<template>
  <div>
    <router-link :to="monitorRoute"
                 class="url">
      {{ monitor | monitorNameOrUrl | truncate(30) }}
    </router-link> certificate has expired
  </div>
</template>

<script>
import common from './common.js'

export default {
  mixins: [
    common
  ]
}
</script>

<template>
  <div>
    <router-link :to="monitorRoute"
                 class="url">
      {{ monitor | monitorNameOrUrl | truncate(30) }}
    </router-link> certificate expires in {{ notification.data.certificate.expires_in }} {{ daysLabel }}
  </div>
</template>

<script>
import common from './common.js'

export default {
  mixins: [
    common
  ],

  computed: {
    daysLabel () {
      return this.notification.data.certificate.expires_in > 1 ? 'days' : 'day'
    }
  }
}
</script>

<template>
  <div>
    <router-link :to="monitorRoute"
                 class="url">
      {{ monitor | monitorNameOrUrl | truncate(30) }}
    </router-link> became online.
    <span v-if="monitor.offline_duration">
      It was down for {{ monitor.offline_duration | offlineDuration }}
    </span>
  </div>
</template>

<script>
import common from './common.js'
import { secondsToHms } from '@/services/utils.js'

export default {
  mixins: [
    common
  ],

  filters: {
    offlineDuration (seconds) {
      return secondsToHms(seconds)
    }
  }
}
</script>

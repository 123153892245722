import notificationTransformer from '@/services/transformers/notificationTransformer.js'

const baseURL = 'profile'

export default {
  /**
   * Update user profile.
   *
   * @param params
   * @returns Promise
   */
  update (params) {
    return axios.put(`${baseURL}`, params).then(response => response.data)
  },

  hasPassword () {
    return axios.get(`${baseURL}/has-password`).then(response => response.data)
  },

  /**
   * Get user notifications.
   *
   * @param params
   * @returns Promise
   */
  async getNotifications (params = {}) {
    const response = await axios.get(`${baseURL}/notifications`, { params })
      .then(response => response.data)

    return {
      items: response.data.map(notificationTransformer),
      meta: response.meta
    }
  },

  /**
   * Mark user notifications as read.
   *
   * @returns Promise
   */
  markNotificationsAsRead () {
    return axios.put(`${baseURL}/notifications/read`)
  },

  /**
   * Connect Twilio account.
   *
   * @param sid
   * @param token
   * @param phone_number
   * @returns Promise
   */
  async connectToTwilio ({ sid, token, phone_number }) {
    const response = await axios.post(`${baseURL}/notifications/connect-to-twilio`, {
      sid,
      token,
      phone_number
    })

    return response.data.success
  },

  async exportUserData () {
    return axios.post(`${baseURL}/export-user-data`)
  },

  delete () {
    return axios.post(`${baseURL}/send-remove-account-email`)
  },

  async getAccountUsage () {
    const response = await axios.get(`${baseURL}/account-usage`)
    return response.data
  },

  getCardDetails () {
    return axios.get(`${baseURL}/card-details`)
  },

  updatePaymentMethod (paymentMethod) {
    return axios.post(`${baseURL}/update-payment-method`, { paymentMethod })
  },

  async saveMonitorsGeneralSettings (data) {
    const response = await axios.post(`${baseURL}/monitors-general-settings/general`, data)
    return response.data
  },

  async saveMonitorsGeneralRequestSettings (data) {
    const response = await axios.post(`${baseURL}/monitors-general-settings/request`, data)
    return response.data
  },

  async saveMonitorGeneralResponseSettings (data) {
    const response = await axios.post(`${baseURL}/monitors-general-settings/response`, data)
    return response.data
  },

  async getMonitorsGeneralSettings () {
    const response = await axios.get(`${baseURL}/monitors-general-settings`)
    return response.data
  },

  checkTelegram () {
    return axios.get(`${baseURL}/checkTelegram`).then(response => response.data)
  },

  leaveTelegramChat (chat_id) {
    return axios.delete(`${baseURL}/leaveTelegramChat/${chat_id}`).then(response => response.data)
  },

  async retryPayment () {
    const response = await axios.post(`${baseURL}/retry-payment`)
    return response.data.message
  }
}

<template>
  <div class="Notifications">
    <user-notifications />
  </div>
</template>

<script>
import UserNotifications from '@/components/Account/Notifications/Notifications.vue'

export default {
  components: {
    UserNotifications
  },

  metaInfo: {
    title: 'Notifications'
  }
}
</script>
